import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
import Footer from "../Footer";
import {Helmet} from "react-helmet";

function Ourteam() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //     function handleScroll() {
  //         const fourthSection = document.querySelector("#section-4");
  //         const header = document.querySelector("header");
  //         const { top } = fourthSection.getBoundingClientRect();
  //         const headerHeight = header.offsetHeight;
  //         setIsScrolled(top <= headerHeight);
  //     }

  //     window.addEventListener("scroll", handleScroll);
  //     return () => {
  //         window.removeEventListener("scroll", handleScroll);
  //     };
  // }, []);
  return (
    <>
      <div className={isScrolled ? "header scrolled" : "header"}>
        <Header />
        <Helmet>
          <title>
            About Us | Law Blocks AI - Simplify Your Legal Workflows
          </title>
          <meta
            property="og:title"
            content="About Us | Law Blocks AI - Simplify Your Legal Workflows"
          />
          <meta
            property="og:description"
            content="Learn about Law Blocks AI, a leading legal tech ecosystem that leverages AI and blockchain to simplify legal tasks. Discover our mission to revolutionize legal processes and our vision to transform the legal industry with cutting-edge technology."
          />
          {/* <meta
          property="og:image"
          content="https://lawblocks.io/assets/about-us-thumbnail.jpg"
        /> */}
          <meta property="og:url" content="https://lawblocks.io/about-us" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <main>
          <section className="section_defualt bg-gray_section2 paddig_top_secion">
            <Container
              maxWidth="lg"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className="d-flex justify-content-center mb-4"
                >
                  <div
                    className=" overvide headeing_main justify-content-center align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">Our Team</h2>
                    <p className="text-center">
                      Our team consist of legal & blockchain experts
                    </p>
                  </div>
                </Grid>
                <div class="cards-container">
                  <Grid
                    container
                    spacing={3}
                    className="justify-content-center"
                  >
                    <Grid item xs={12} md={12} lg={4} className="d-flex">
                      <div class="card">
                        <img
                          src={require("../../Image/banner/hitomi.webp")}
                          alt="Hitomi Ikeda"
                        />
                        <div className="flex-grow-s">
                          <h3>Hitomi Ikeda</h3>
                          <p>CEO and Co-founder</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} className="d-flex">
                      <div class="card">
                        <img
                          src={require("../../Image/banner/asish.webp")}
                          alt="Adv. Ashish Baphana"
                        />
                        <div>
                          <h3>Adv. Ashish Baphana</h3>
                          <p>Sr. Arbitrator / Co-founder of Law Blocks</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} className="d-flex">
                      <div class="card">
                        <img
                          src={require("../../Image/banner/ritesh.webp")}
                          alt="Ritesh Kakkad"
                        />
                        <div>
                          <h3>Ritesh Kakkad</h3>
                          <p>Co-Founder, XDC Network Advisory Board Member</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} className="d-flex">
                      <div class="card">
                        <img
                          src={require("../../Image/banner/atul.webp")}
                          alt="Atul Khekade"
                        />
                        <div>
                          <h3>Atul Khekade</h3>
                          <p>Co-Founder, XDC Network Advisory Board Member</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} className="d-flex">
                      <div class="card">
                        <img
                          src={require("../../Image/banner/riku.webp")}
                          alt="Riku Yamaguchi"
                        />
                        <div>
                          <h3>Riku Yamaguchi</h3>
                          <p>Ecosystem Development Manager</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} className="d-flex">
                      <div class="card">
                        <img
                          src={require("../../Image/banner/dila.webp")}
                          alt="Dila Khamraeva"
                        />
                        <div>
                          <h3>Dila Khamraeva</h3>
                          <p>Financial Expert Advisory Board Member</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} className="d-flex">
                      <div class="card">
                        <img
                          src={require("../../Image/banner/santhosh.webp")}
                          alt="Dr Santosh Jaiswal"
                        />
                        <div>
                          <h3>Dr Santosh Jaiswal</h3>
                          <p>
                            Sr. Arbitrator / Retd Judge Advisory Board Member
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} className="d-flex">
                      <div class="card">
                        <img
                          src={require("../../Image/banner/alok.webp")}
                          alt="Adv. Alok Srivastava"
                        />
                        <div>
                          <h3>Adv. Alok Srivastava</h3>
                          <p>Sr. Arbitrator Advisory Board Member</p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Container>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default Ourteam;
