import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "What types of disputes can be resolved through ADR?",
      answer:
        "ADR can be used to resolve a variety of disputes, including commercial disagreements, contractual conflicts, employment issues, and personal disputes. Our services are designed to handle disputes across different sectors and industries.",
    },
    {
      question: "How do I start the ADR process with Law Blocks AI?",
      answer:
        "To initiate the ADR process, simply submit your dispute through our platform. Our AI system will guide you through the process, suggest suitable ADR methods, and connect you with the appropriate experts to resolve your issue.",
    },
    {
      question: "Are ADR decisions legally binding?",
      answer:
        "The binding nature of ADR outcomes depends on the method used. Arbitration typically results in a binding decision that can be enforced in court if necessary. Mediation and conciliation often lead to agreements that are not legally binding unless formalized through additional legal documentation.",
    },
    {
      question:
        "How does Law Blocks AI ensure the security and confidentiality of ADR processes?",
      answer:
        "We use state-of-the-art encryption and blockchain technology to safeguard all ADR processes. This ensures that your dispute details and any agreements reached are kept confidential and secure throughout the resolution process.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>
          Alternate Dispute Resolution (ADR) with Law Blocks AI - Fast, Smart,
          and Transparent
        </title>
        <meta
          property="og:title"
          content="Alternate Dispute Resolution (ADR) with Law Blocks AI - Fast, Smart, and Transparent"
        />
        <meta
          property="og:description"
          content="Law Blocks AI revolutionizes ADR with cutting-edge AI technology, offering faster, smarter, and more transparent dispute resolution. Experience mediation, arbitration, and conciliation in a streamlined process while ensuring confidentiality and global compliance."
        />
        {/* <meta
  property="og:image"
  content="https://lawblocks.io/assets/adr-resolution-thumbnail.jpg"
/> */}
        <meta property="og:url" content="https://lawblocks.io/adr" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Alternate Dispute Resolution (ADR) with Law Blocks AI - Fast, Smart, and Transparent"
        />
        <meta
          name="twitter:description"
          content="Unlock the future of dispute resolution with Law Blocks AI. Our AI-powered platform streamlines ADR through mediation, arbitration, and conciliation while maintaining high security and confidentiality standards."
        />
        {/* <meta
  name="twitter:image"
  content="https://lawblocks.io/assets/adr-resolution-thumbnail.jpg"
/> */}
      </Helmet>

      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc uploaddocs bg-white didgtal_hero bg-image-new"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <div className="hero_seaction_law align-items-start alter-top-paddings ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Alternate Dispute Resolution (ADR)
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Law Blocks AI brings the future of ADR to your fingertips.
                    Faster, smarter, and transparent ADR solutions
                  </p>
                  <Button
                    onClick={navigate}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Get started
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className="d-flex align-items-center justify-content-center"
              ></Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt  bg-gray_section pt-4">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={9}>
                  <div
                    className="overvide align-items-center justify-content-center mb-5"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">What is ADR?</h2>
                    <p className="text-center">
                      Law Blocks AI has strategically partnered with some of the
                      most respected exchanges in the crypto space, making LBT
                      token trading accessible globally. Each exchange offers
                      unique features tailored to crypto enthusiasts, traders,
                      and investors.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="logo_slide">
                  <div
                    class="keyfetupload-grid item_white logo_barnd option_barnd"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0">
                        <img
                          src={require("../../Image/banner/mediation.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>Mediation</h3>
                      <p>
                        A neutral third party helps disputing parties reach a
                        mutually acceptable agreement.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0">
                        <img
                          src={require("../../Image/banner/arbitration.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>Arbitration</h3>
                      <p>
                        A neutral third party makes a binding decision after
                        hearing both sides of the dispute.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0">
                        <img
                          src={require("../../Image/banner/conciliatior.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>Conciliation</h3>
                      <p>
                         Similar to mediation but with more direct involvement
                        in suggesting solutions.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">
                    Why Choose Law Blocks AI for ADR?
                  </h2>
                </div>
              </Grid>
            </Grid>
            <div class="features-container pl-0 pr-0 pb-0 margin_zeeor_mobile">
              <div class="feature-box ml-0">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/artificial-intelligence_2.webp")}
                    alt=""
                  />
                </div>
                <h3>AI-Enhanced Process</h3>
                <p>
                   Our platform leverages AI to streamline and optimize
                  Alternative Dispute Resolution (ADR) processes, delivering
                  quicker, more accurate outcomes.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/world_1.webp")}
                    alt=""
                  />
                </div>
                <h3>Global Reach</h3>
                <p>
                  We offer ADR services that align with international standards,
                  making us a trusted partner for resolving global disputes
                  efficiently.
                </p>
              </div>
            </div>
            <div class="features-container pl-0 pr-0 pt-0 margin_zeeor_mobile">
              <div class="feature-box ml-0">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/confident.webp")}
                    alt=""
                  />
                </div>
                <h3>Confidentiality</h3>
                <p>
                  We prioritise the privacy of all parties involved, and we
                  ensure secure handling and storage of all sensitive
                  information involved in the dispute resolution process.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img src={require("../../Image/banner/expert.webp")} alt="" />
                </div>
                <h3>Expertise</h3>
                <p>
                  Our dedicated team of legal experts and AI specialists
                  collaborate to provide high-quality, tailored ADR solutions
                  that meet your specific needs.
                </p>
              </div>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt bg-gray_section colors_white"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">How It Works</h2>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div class="features-container pl-0 pr-0 gap_3">
                  <div class="feature-box ml-0 flex_align_werp">
                    <div className="circule_radioue">
                      <h3 className="h3_color">1</h3>
                    </div>
                    <h3 className="text-center">Initiate Dispute</h3>
                  </div>

                  <div class="feature-box flex_align_werp">
                    <div className="circule_radioue">
                      <h3 className="h3_color">2</h3>
                    </div>
                    <h3 className="text-center">Select ADR Method</h3>
                  </div>
                  <div class="feature-box flex_align_werp">
                    <div className="circule_radioue">
                      <h3 className="h3_color">3</h3>
                    </div>
                    <h3 className="text-center">Resolution Process</h3>
                  </div>
                  <div class="feature-box ml-0 flex_align_werp">
                    <div className="circule_radioue">
                      <h3 className="h3_color">4</h3>
                    </div>
                    <h3 className="text-center">Finalize Agreement</h3>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion faqs={faqs} />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
