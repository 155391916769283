import React, { useState, useEffect } from "react";
import Header from "../componant/HeaderNew";
import { Button } from "@mui/material";
import SocialLinks from "../componant/SocialLinks";
import { LocationOn, Email, Telegram } from "@mui/icons-material";

import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const { top } = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "What is a Digital Signature?",
      answer:
        "A Digital Signature is an electronic form of a signature that verifies the authenticity and integrity of a digital document. It provides a secure and legally binding way to sign documents electronically, ensuring that the signer is who they claim to be and that the document has not been altered.",
    },
    {
      question: "How does Law Blocks AI ensure the security of my documents?",
      answer:
        "Law Blocks AI ensures the security of your documents through end-to-end encryption, multi-factor authentication, and an audit trail. End-to-end encryption protects your data from unauthorized access, while multi-factor authentication verifies the identity of each signer. The audit trail provides a comprehensive record of the signing process, ensuring transparency and accountability.",
    },
    {
      question: "Is it easy to upload documents on Law Blocks AI?",
      answer:
        "Yes, the process is straightforward and user-friendly, ensuring even those with minimal technical expertise can easily upload documents.",
    },
    {
      question: "How secure are the documents stored on the blockchain?",
      answer:
        "Documents are encrypted and stored with an immutable record, ensuring they are tamper-proof and secure.",
    },
    {
      question: "Can I access my documents anytime?",
      answer:
        "Yes, documents stored on Law Blocks AI can be accessed quickly and efficiently, from anywhere in the world.",
    },
    {
      question: "What types of documents can be stored?",
      answer:
        "Law Blocks AI supports various file formats, making it suitable for legal contracts, medical records, financial documents, and more.",
    },
    {
      question: "Is there customer support available?",
      answer:
        "Yes, Law Blocks AI offers comprehensive support to assist with any questions or issues.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>Our Partners</title>
      </Helmet>
      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc  bg-gray_section-hero heaight_excahnge section_fixed_height_sedcion banner_vierutal_hero height_contact new_hig"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className="">
                <div className="hero_seaction_law align-items-center  fixed_hero_height ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-center mb-2 w-100"
                  >
                    Our Partners
                  </h1>
                  <p
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    We collaborate with top tech and legal innovators to
                    integrate AI, blockchain, and legal tech, transforming legal
                    services with enhanced efficiency, security, and
                    transparency. Together, we empower firms to modernize and
                    thrive.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt p-5">
          <Container
            maxWidth="lg"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="200"
          >
            <Grid container spacing={3} className="justify-content-center">
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/pabna.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/unique_human.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/Crypto_wally.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-1.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-2.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-3.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-5.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-6.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-7.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-8.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-9.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-10.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-12.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-13.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-14.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-15.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-16.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-17.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-18.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-19.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-20.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-21.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-22.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-23.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-24.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-25.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-26.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-27.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-28.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-29.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-30.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-31.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-32.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-33.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-36.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={1} className="empty-data">
                 
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-37.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-38.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-39.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={1}
                className="empty-data"
              ></Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-34.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={3}>
                <div className="card_clas_parner">
                  <img
                    src={require("../../Image/partners/partner-35.png")}
                    alt="brand"
                    className="img-fluid"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
