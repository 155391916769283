import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "How does Law Blocks AI's dispute resolution platform work?",
      answer:
        "Our platform uses advanced AI technology to assess and manage disputes efficiently. After submitting your dispute, our AI evaluates the case and suggests the most suitable resolution method—mediation, arbitration, or conciliation—ensuring a streamlined and effective process.",
    },
    {
      question: "What types of disputes can be resolved using your platform?",
      answer:
        "Our dispute resolution services cover a broad spectrum of issues, including commercial conflicts, contractual disputes, and personal disagreements. Our AI-driven approach is designed to handle various types of disputes with precision.",
    },
    {
      question:
        "Are the resolutions provided through your platform legally enforceable?",
      answer:
        "The enforceability of the resolution depends on the chosen ADR method. Arbitration typically results in a legally binding decision, while mediation and conciliation may lead to agreements that are non-binding unless formalised through additional legal steps.",
    },
    {
      question:
        "How does Law Blocks AI protect the confidentiality of the dispute resolution process?",
      answer:
        "We employ cutting-edge encryption and blockchain technology to ensure the utmost security and confidentiality of all information involved in the dispute resolution process. This safeguards your data and the details of your resolution from unauthorized access.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>
          Dispute Resolution with Law Blocks AI - Smart, Secure, and Efficient
        </title>
        <meta
          property="og:title"
          content="Dispute Resolution with Law Blocks AI - Smart, Secure, and Efficient"
        />
        <meta
          property="og:description"
          content="Resolve disputes efficiently with Law Blocks AI. Our platform uses AI technology to manage disputes, offering mediation, arbitration, and conciliation, all while ensuring confidentiality with cutting-edge encryption and blockchain technology."
        />
        {/* <meta
  property="og:image"
  content="https://lawblocks.io/assets/dispute-resolution-thumbnail.jpg"
/> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/dispute-resolution"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Dispute Resolution with Law Blocks AI - Smart, Secure, and Efficient"
        />
        <meta
          name="twitter:description"
          content="Streamline dispute resolution with Law Blocks AI. Our AI-enhanced platform ensures efficient resolution methods like mediation, arbitration, and conciliation, while maintaining security through advanced encryption."
        />
        {/* <meta
  name="twitter:image"
  content="https://lawblocks.io/assets/dispute-resolution-thumbnail.jpg"
/> */}
      </Helmet>

      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc uploaddocs bg-white didgtal_hero bg-image-new new-iamge_alter"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <div className="hero_seaction_law align-items-start ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Dispute Resolution
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0 text-law-w"
                  >
                    Law Blocks AI delivers smarter, faster dispute resolution,
                    empowering you with futuristic technology and legal
                    expertise.
                  </p>
                  <Button
                    onClick={navigate}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Get started
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-items-center justify-content-center"
              ></Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt  bg-gray_section pt-4">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={9}>
                  <div
                    className="overvide align-items-center justify-content-center mb-5"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">
                      Why Choose Our ADR Services?
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="logo_slide">
                  <div
                    class="keyfetupload-grid item_white logo_barnd option_barnd width_50"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0">
                        <img
                          src={require("../../Image/banner/AI-dive.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>AI-Driven Precision</h3>
                      <p>
                        Our platform leverages advanced AI tools to analyze
                        disputes and recommend the most effective dispute
                        resolution strategies, enhancing both accuracy and
                        speed.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0">
                        <img
                          src={require("../../Image/banner/Glob-img.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>Global Expertise</h3>
                      <p>
                        We provide Alternative Dispute Resolution (ADR)
                        solutions that comply with international standards,
                        making us a trusted partner for resolving global
                        disputes seamlessly.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0">
                        <img
                          src={require("../../Image/banner/secure-1.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>Confidential and Secure</h3>
                      <p>
                         Your privacy is our priority. We safeguard your
                        information with advanced encryption and blockchain
                        technology, ensuring the highest levels of
                        confidentiality throughout the ADR process.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <div className="img_contaoiner p-0">
                        <img
                          src={require("../../Image/banner/flexi.webp")}
                          className="img-fluid w-100 m-0"
                        />
                      </div>
                      <h3>Flexible Solutions</h3>
                      <p>
                          Choose from mediation, arbitration, or conciliation to
                        fit your specific needs, and let our AI-enhanced system
                        guide you toward a fair and satisfactory outcome.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">How It Works</h2>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div class="features-container pl-0 pr-0 gap_3 ">
                  <div class="feature-box ml-0 flex_align_werp">
                    <div className="circule_radioue">
                      <h3 className="h3_color">1</h3>
                    </div>
                    <h3 className="text-center">Submit Your Dispute</h3>
                  </div>

                  <div class="feature-box flex_align_werp">
                    <div className="circule_radioue">
                      <h3 className="h3_color">2</h3>
                    </div>
                    <h3 className="text-center">Resolution Process</h3>
                  </div>
                  <div class="feature-box flex_align_werp">
                    <div className="circule_radioue">
                      <h3 className="h3_color">3</h3>
                    </div>
                    <h3 className="text-center">Final Agreement</h3>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion faqs={faqs} />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
