import React, { useState, useEffect } from "react";
import Header from "../componant/HeaderNew";
import { Button } from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const { top } = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: " Can AI Legal Chat Replace a Lawyer?",
      answer:
        "While AI Legal Chat provides quick and accurate responses to common legal issues, it does not replace the nuanced expertise of a lawyer in complex legal matters. However, it serves as a valuable tool for basic legal guidance and document reviews.",
    },
    {
      question: "Is AI Legal Chat Secure?",
      answer:
        "Yes, security is a top priority for AI Legal Chat. All communications and data shared on our platform are encrypted and stored securely, ensuring your privacy and the confidentiality of your legal matters.",
    },
    {
      question: "Can AI Legal Chat Provide Personalized Legal Advice?",
      answer:
        "AI Legal Chat can offer general legal information based on the data it processes. However, it cannot provide personalized legal advice tailored to individual circumstances. For specific legal advice, consulting with a licensed attorney is essential.",
    },
    {
      question: "What Types of Legal Issues Can AI Legal Chat Assist With?",
      answer:
        "AI Legal Chat is well-suited for addressing common legal inquiries, such as understanding legal terminology, guiding through basic legal procedures, and offering insights into standard legal documents. However, for complex cases or matters requiring interpretation of the law, a human lawyer's expertise is necessary.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>AI Legal Chat | AI Lawyer | Legal information AI</title>
        <meta
          property="og:title"
          content=" Clarify your legal queries with the Law Blocks AI, AI Lawyer for AI legal Chat. An AI that provides all the legal information.
          "
        />
        <meta
          property="og:description"
          content="Discover AI Legal Chat by Law Blocks AI. Our AI-powered chatbots offer instant, accurate legal advice 24/7, helping you with common legal questions, document reviews, and more. Accessible, cost-effective, and user-friendly legal assistance at your fingertips."
        />
        {/* <!-- <meta
  property="og:image"
  content="https://lawblocks.io/assets/ai-legal-chat-thumbnail.jpg"
/> --> */}
        <meta property="og:url" content="https://lawblocks.io/ai-legal-chat" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="AI Legal Chat - Instant Legal Advice Powered by Artificial Intelligence"
        />
        <meta
          name="twitter:description"
          content="AI Legal Chat by Law Blocks AI offers 24/7 legal assistance through advanced AI-powered chatbots. Get reliable, cost-effective legal advice, document assistance, and more anytime, anywhere."
        />
        {/* <!-- <meta
  name="twitter:image"
  content="https://lawblocks.io/assets/ai-legal-chat-thumbnail.jpg"
/> --> */}
      </Helmet>
      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc  bg-gray_section-hero  section_fixed_height_sedcion banner_vierutal_hero bg_imge_non tab_height ai-chat-h"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-item-center justiy-content-center"
              >
                <div className="hero_seaction_law align-items-start justify-content-center fixed_hero_height adr-top-padding adr-top-padding_2">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    AI Legal Chat
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-justify m-0"
                  >
                    Get Instant Legal Advice with AI. Law Blocks AI delivers
                    fast, efficient legal answers through AI-powered chatbots,
                    available 24/7.
                  </p>
                  <Button
                    onClick={navigate}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Try for free
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="imag_widtjas image_contain_digital d-flex justify-content-center align-items-center img-padding-top"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <img
                    alt="AI Legal Chat"
                    src={require("../../Image/banner/leagalchat.webp")}
                    className="img-fluid img_center_section_wallet  show_light"
                  />
                  <img
                    alt="AI Legal Chat"
                    src={require("../../Image/banner/chatdark.webp")}
                    className="img-fluid img_center_section_wallet show_dark"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt ">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt="AI Legal Chat"
                      src={require("../../Image/banner/legalchat2.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>What Is AI Legal Chat?</h2>
                    <p className="text-justify">
                      AI Legal Chat is a user-friendly tool that provides
                      instant legal advice through an AI-powered chat. It uses
                      advanced algorithms and a vast legal database to answer
                      common questions, offer basic guidance, and help with
                      document review. Whether you're an individual or a small
                      business, AI Legal Chat makes legal assistance accessible
                      and easy to use—just ask your questions and get answers
                      tailored to your needs.
                    </p>
                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                      data-aos="zoom-in"
                      data-aos-duration="500"
                    >
                      Get Started
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section className="section_defualt bg-gray_section">
          <Container maxWidth="lg" className="mt-5">
            <div className="padding_adjust">
              <Grid
                container
                spacing={3}
                className="d-flex justify-content-center"
              >
                <Grid item xs={12} md={12} lg={8}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">
                      Benefits of Law Blocks AI Legal Chat
                    </h2>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className="law_aichat"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <div class="container1">
                    <div class="item">
                      <h2>1</h2>
                      <p>
                        <b> 24/7 Availability</b>
                        <br />
                        Get instant legal advice anytime, day or night.
                      </p>
                    </div>
                    <div class="item">
                      <h2>2</h2>
                      <p>
                        <b>User-Friendly Interface</b>
                        <br />
                        Easy-to-use platform, accessible to everyone.
                      </p>
                    </div>
                    <div class="item">
                      <h2>3</h2>
                      <p>
                        <b> Cost-Effective</b>
                        <br />
                        Get instant legal advice anytime, day or night.
                      </p>
                    </div>
                    <div class="item">
                      <h2>4</h2>
                      <p>
                        <b> AI-Powered Accuracy</b>
                        <br />
                        Reliable legal guidance with advanced AI technology.
                      </p>
                    </div>
                    <div class="item">
                      <h2>5</h2>
                      <p>
                        <b> Tailored Solutions</b>
                        <br />
                        Receive answers customized to your specific legal needs.
                      </p>
                    </div>
                    <div class="item">
                      <h2>6</h2>
                      <p>
                        <b>Time-Saving</b>
                        <br />
                        Fast responses help you make quicker decisions.
                      </p>
                    </div>
                    <div class="item">
                      <h2>7</h2>
                      <p>
                        <b>Document Assistance</b>
                        <br />
                        AI can assist with reviewing and drafting legal
                        documents.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <div class="features-container pl-0 pr-0 margin_zeeor_mobile">
                  <div class="feature-box ml-0 flex_align_werp">
                    <div className="circule_radioue">
                      <img
                        src={require("../../Image/banner/login.webp")}
                        alt="AI Legal Chat"
                      />
                    </div>
                    <h3 className="text-center">Sign Up and Log In</h3>
                  </div>

                  <div class="feature-box flex_align_werp">
                    <div className="circule_radioue">
                      <img
                        src={require("../../Image/banner/lawer.webp")}
                        alt="AI Legal Chat"
                      />
                    </div>
                    <h3 className="text-center">Choose AI Legal Chat</h3>
                  </div>
                  <div class="feature-box flex_align_werp">
                    <div className="circule_radioue">
                      <img
                        src={require("../../Image/banner/chat.webp")}
                        alt="AI Legal Chat"
                      />
                    </div>
                    <h3 className="text-center">Enter your Legal Queries</h3>
                  </div>
                  <div class="feature-box ml-0 flex_align_werp">
                    <div className="circule_radioue">
                      <img
                        src={require("../../Image/banner/submit.webp")}
                        alt="AI Legal Chat"
                      />
                    </div>
                    <h3 className="text-center">Get Instant Solution</h3>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <div
                  className="overvide align-items-start"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-left">How to Use AI Legal Chat</h2>
                  <p className="text-justify">
                    AI Legal Chat provides instant, reliable legal advice
                    through AI-driven insights. Designed for legal professionals
                    and clients, it streamlines communication and
                    decision-making, making legal services more accessible and
                    efficient.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt  why_digilat bg-gray_section">
          <Container maxWidth="xl">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/whychat.webp")}
                      className="img-fluid"
                      alt="AI Legal Chat"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start pr-5"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Why Choose AI Legal Chat?</h2>
                    {/* <p>
                      There are many reasons why AI Legal Chat is becoming a
                      go-to solution for modern legal needs. Its blend of
                      cutting-edge technology, affordability, and convenience
                      makes it a strong alternative to traditional legal
                      services.
                    </p> */}

                    <ul>
                      <li>
                        <b>Advanced AI Technology for Reliable Legal Support</b>
                      </li>
                      <li>
                        <b>Accessible anywhere anytime</b>
                      </li>
                      <li>
                        <b>
                          Trusted AI Legal Chatbot for Common Legal Questions
                        </b>
                      </li>
                      <li>
                        <b>Cost-Effective Legal Assistance for All</b>
                      </li>
                      <li>
                        <b>Tailored Legal Responses for Specific Needs</b>
                      </li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    To help you better understand the capabilities of AI Legal
                    Chat, we’ve compiled answers to some of the most common
                    questions about our service.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion faqs={faqs} />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
