import React, { useState, useEffect } from "react";
import Header from "../pages/componant/HeaderNew";
import { Button } from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
import Bgvedio from "../Image/globe-fiat-block.mp4";
import BannerUI from "./Banner";
import TestimonialSlider from "./componant/Slider";
import ContactUs from "./componant/Contact";
import FAQAccordion from "./componant/FAQAccordion";
import ImageWithModal from "./componant/ImageWithModal ";
import SectionWithVideoBackground from "./componant/Vedio";
import BlogCard from "./componant/BlogCard";
import { Helmet } from "react-helmet";
function Home() {
  const posts = [
    {
      slug: "marketplace-for-lawyers-a-new-era-of-legal-service-delivery-law-blocks-ai",
      title:
        "Marketplace for Lawyers: A New Era of Legal Service Delivery - Law Blocks AI",
      category: "Marketplace for Lawyers",
      date: "July 12, 2024",
      description:
        "Explore how Law Blocks AI is transforming legal service delivery with a new marketplace for lawyers. Discover the benefits of AI-powered legal solutions, streamlined processes, and enhanced client-lawyer interactions.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/Marketplace_slfuz1.jpg",
    },
    {
      slug: "benefits-of-ai-chat-for-legal-professionals-and-clients",
      title: "The Benefits of AI Chat for Legal Professionals and Clients",
      category: "Global Information AI",
      date: "July 12, 2024",
      description:
        "Discover the advantages of AI chat for legal professionals and clients with Law Blocks AI. Learn how AI-powered chat enhances communication, efficiency, and client satisfaction in the legal industry.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ai_chat_sisdav.jpg",
    },
    {
      slug: "how-digital-signature-features-work-on-law-blocks-ai",
      title: "How Digital Signature Features Work on Law Blocks AI",
      category: "Digital Signature",
      date: "July 12, 2024",
      description:
        "Learn how Law Blocks AI enhances digital signature features to provide secure, efficient, and legally binding document signing. Explore the benefits of digital signatures in streamlining legal processes and ensuring document integrity.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ds_thnxrv.jpg",
    },
  ];

  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const { top } = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header"}>
      <Header />
      <Helmet>
        <title> Law Blocks AI | Web3 and AI in Law | AI for Lawyers</title>
        <meta
          name="Meta Title"
          content="Law Blocks AI | AI in Law | Web3 and AI Legal Solutions"
        />
        <meta
          name="Keywords"
          content="AI in Law, Web3 in Law, Blockchain in Law, AI Legal Solutions, Smart Contracts for Lawyers, Legal Document Automation, Secure Legal Storage, Legal Tech Innovations"
        />
        <meta
          name="description"
          content="An AI tool for Lawyers to make the legal process easy and fast. It makes the document storage secure with blockchain and web3. "
        />
      </Helmet>

      <main>
        <SectionWithVideoBackground />
        {/* <section className="section_banner_hero" id="section-4">
          <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div>

          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div className="hero_seaction_law ">
                  <h1 data-aos="zoom-in" data-aos-duration="500">
                    Law Blocks AI
                  </h1>
                  <h2 data-aos="zoom-in" data-aos-duration="500">
                    {" "}
                    Legal Tech Ecosystem 
                  </h2>
                  <p data-aos="zoom-in" data-aos-duration="500">
                    Upgrade Your legal practice with Law Blocks AI, Powered by
                    XDC Blockchain Network <br />
                    and Artificial intelligence. Solve all your legal issues
                    with us.
                  </p>
                  <Button
                    className="laight_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Signup Now
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section> */}
        <section className="" id="section-4">
          <ImageWithModal />
          <Container maxWidth="xl">
            {/* <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div className=" img_section">
                  <img
                    src={require("../Image/banner/MacBook_Air.png")}
                    className="img-fluid"
                  />
                </div>
              </Grid>
            </Grid> */}
          </Container>
        </section>
        <section className="section_defualt mt-5">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Overview of Law Blocks AI</h2>
                    <p>
                      Law Blocks AI powered by XDC Network was conceived by a
                      group of visionary legal tech enthusiasts who recognized
                      the potential of AI in modernizing legal workflows. Since
                      its beginning, we have focused on harnessing the power of
                      blockchain, AI and Law to address the complexities and
                      inefficiencies often encountered in the legal industry.
                    </p>
                    <p>
                      Law firms of all sizes can benefit from the efficiencies
                      and capabilities offered by Law Blocks AI. For solo
                      practitioners, Law Blocks AI offers a way to compete with
                      larger firms by providing access to the same level of
                      advanced research and document analysis tools without the
                      need for a large support staff.
                    </p>
                    <Button onClick={navigate} className="laight_button">
                      Get Started
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div
                    className="imag_widtjas"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../Image/banner/robo-11.webp")}
                      className="img-fluid"
                      alt="Law Blocks AI"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt bg-gray_section">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center mb-4"
              >
                <div
                  className=" overvide headeing_main"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center"> Features of Law Blocks AI</h2>
                  {/* <p className="text-center">
                    Excepteur sint occaecat cupidatat non proident, sunt in{" "}
                    <br />
                    culpa qui officia deserunt mollit anim.
                  </p> */}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="300"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",

                    width: "100%",
                    padding: "16px 6px",
                  }}
                  className="card_dark"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <div className="imag_caed new_icon">
                        <img
                          src={require("../Image/banner/icon-1.webp")}
                          className="img-fluid"
                          alt="Law Blocks AI"
                        />
                      </div>
                      <h3>AI legal document </h3>
                      <p>
                        AI Legal Documents utilize advanced algorithms to draft,
                        review, and customize legal documents accurately and
                        efficiently.
                      </p>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{ justifyContent: "start", padding: "0px 10px" }}
                  >
                    <a href="/ai-legal-document-generator">
                      <Button
                        size="small"
                        className="laight_button card_butotn"
                      >
                        Learn More
                      </Button>
                    </a>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",

                    padding: "16px 6px",
                  }}
                  className="card_dark"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <div className="imag_caed new_icon">
                        <img
                          alt="Law Blocks AI"
                          src={require("../Image/banner/icon-2.webp")}
                          className="img-fluid"
                        />
                      </div>
                      <h3>Smart Legal Contracts </h3>
                      <p>
                        Smart Legal Contracts automatically execute and enforce
                        contract terms using blockchain technology, ensuring
                        security and transparency.
                      </p>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{ justifyContent: "start", padding: "0px 10px" }}
                  >
                    <a href="/smart-legal-contracts">
                      <Button
                        size="small"
                        className="laight_button card_butotn"
                      >
                        Learn More
                      </Button>
                    </a>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",

                    padding: "16px 6px",
                  }}
                  className="card_dark"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <div className="imag_caed new_icon">
                        <img
                          alt="Law Blocks AI"
                          src={require("../Image/banner/icon-3.webp")}
                          className="img-fluid"
                        />
                      </div>
                      <h3>Digital Signature </h3>
                      <p>
                        Digital Signatures provide a secure and legally binding
                        way to sign documents electronically, streamlining the
                        authentication process.
                      </p>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{ justifyContent: "start", padding: "0px 10px" }}
                  >
                    <a href="/digital-signature">
                      <Button
                        size="small"
                        className="laight_button card_butotn"
                      >
                        Learn More
                      </Button>
                    </a>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="300"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",

                    padding: "16px 6px",
                  }}
                  className="card_dark"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <div className="imag_caed new_icon">
                        <img
                          alt="Law Blocks AI"
                          src={require("../Image/banner/icon-4.webp")}
                          className="img-fluid"
                        />
                      </div>
                      <h3>Uploading Blockchain </h3>
                      <p>
                        Uploading Blockchain integrates a decentralized ledger
                        to store and verify legal documents, enhancing their
                        security and immutability.
                      </p>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{ justifyContent: "start", padding: "0px 10px" }}
                  >
                    <a href="/secure-document-storage-blockchain">
                      <Button
                        size="small"
                        className="laight_button card_butotn"
                      >
                        Learn More
                      </Button>
                    </a>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-between",
                    padding: "16px 6px",
                  }}
                  className="card_dark"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <div className="imag_caed new_icon">
                        <img
                          alt="Law Blocks AI"
                          src={require("../Image/banner/icon-5.webp")}
                          className="img-fluid"
                        />
                      </div>
                      <h3>Alternate Dispute Resolution (ADR) </h3>
                      <p>
                        When disputes arise between parties, they can be
                        resolved through ADR mechanisms such as Mediation and
                        Arbitration, along with arbitration courts globally.
                      </p>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{ justifyContent: "start", padding: "0px 10px" }}
                  >
                    <a href="/arbitration">
                      <Button
                        size="small"
                        className="laight_button card_butotn"
                      >
                        Learn More
                      </Button>
                    </a>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "16px 6px",
                  }}
                  className="card_dark"
                >
                  <CardContent>
                    <div className="card_inside_content">
                      <div className="imag_caed new_icon">
                        <img
                          alt="Law Blocks AI"
                          src={require("../Image/banner/icon-6.webp")}
                          className="img-fluid"
                        />
                      </div>
                      <h3>Global Information AI</h3>
                      <p>
                        Global Information AI aggregates and analyzes legal data
                        worldwide, offering comprehensive insights and
                        up-to-date information.
                      </p>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{ justifyContent: "start", padding: "0px 10px" }}
                  >
                    <a href="/ai-legal-chat">
                      <Button
                        size="small"
                        className="laight_button card_butotn"
                      >
                        Learn More
                      </Button>
                    </a>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt ">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center mb-4"
              >
                <div
                  className=" overvide headeing_main align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center br-none ">
                    Working Process of AI-based Legal<br />
                    Smart Contracts
                  </h2>
                  <p className="text-center">
                    Law Blocks AI Platform generates, verifies, and securely
                    stores legal documents using AI, integrating with digital
                    wallets for  
                    seamless management. Documents are digitally signed and
                    uploaded to the blockchain for immutable storage,  
                    ensuring authenticity and accessibility.
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <img
                  alt="Law Blocks AI"
                  src={require("../Image/banner/main-flow.webp")}
                  className="img-fluid whiteshow"
                />
                <img
                  alt="Law Blocks AI"
                  src={require("../Image/banner/darrkmode_flw.webp")}
                  className="img-fluid blackshow"
                />
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt ">
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className=" mb-4"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <div className=" overvide headeing_main align-items-center">
                  <h2 className="Text-center">Our Pricing</h2>
                  <p className="text-center">Plans that scale with growth</p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                className="d-flex justify-content-between"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                {/* <Card
                  sx={{
                    width: "100%",
                    padding: "16px 16px",
                  }}
                  className="card_bg_0 d-flex justify-content-between flex-column "
                > */}
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between ",

                    width: "100%",
                    padding: "16px 16px",
                  }}
                  className="card_dark darkPrice hovwer_card"
                >
                  <CardContent
                    sx={{
                      padding: "16px 5px",
                    }}
                  >
                    <div className="card_inside_content pricong">
                      <div className="imag_caed border-sq">
                        <img
                          alt="Law Blocks AI"
                          src={require("../Image/banner/user.webp")}
                          className="img-fluid"
                        />
                      </div>
                      <p className="">Freemium Plan</p>
                      <h3 className="">Free</h3>

                      <div
                        style={{
                          width: "100%",
                          height: 1,
                        }}
                        className="mt-3 mb-3 grdionet"
                      ></div>
                      <ul>
                        <li className="">
                          <i class="ri-checkbox-circle-line "></i>
                          Access to Basic AI-Powered Document Automation
                        </li>
                        <li className="">
                          <i class="ri-checkbox-circle-line "></i>
                          Basic Contract Creation (up to 3 contracts/month)
                        </li>
                        <li className="">
                          <i class="ri-checkbox-circle-line "></i>
                          Standard Legal Research Tools
                        </li>
                        <li className="">
                          <i class="ri-checkbox-circle-line "></i>
                          Community Support
                        </li>
                        <li className="">
                          <i class="ri-checkbox-circle-line "></i>
                          Access to Basic Learning Resources
                        </li>
                        <li className="">
                          <i class="ri-checkbox-circle-line "></i>
                          Digital Signature
                        </li>
                      </ul>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{
                      justifyContent: "start",
                      padding: "0px 10px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                    className=""
                  >
                    <p className="text_colos">For Personal</p>
                    <Button
                      size="small"
                      className="laight_button card_butotn padun w-100 "
                      onClick={() => (window.location.href = "/comming-soon")}
                    >
                      Request Access
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                className="d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between ",

                    width: "100%",
                    padding: "16px 16px",
                  }}
                  className="card_dark darkPrice hovwer_card"
                >
                  <CardContent
                    sx={{
                      padding: "16px 5px",
                    }}
                  >
                    <div className="card_inside_content pricong">
                      <div className="imag_caed">
                        <img
                          alt="Law Blocks AI"
                          src={require("../Image/banner/enterprise.webp")}
                          className="img-fluid"
                        />
                      </div>
                      <p>Basic Plan</p>

                      <h3>$29/month</h3>
                      <div
                        style={{
                          width: "100%",
                          height: 1,
                        }}
                        className="mt-3 mb-3 grdionet"
                      ></div>
                      <ul>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Access to
                          AI-Powered Document Automation
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Basic Contract
                          Creation (up to 10 contracts/month)
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Standard Legal
                          Research Tools
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Customer
                          Support
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Secure Document
                          Storage on Blockchain
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Digital
                          Signature
                        </li>
                      </ul>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{
                      justifyContent: "start",
                      padding: "0px 10px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <p className="text_colos">For Freelancers & Startups</p>
                    <Button
                      size="small"
                      className="laight_button card_butotn padun w-100"
                      onClick={() => (window.location.href = "/comming-soon")}
                    >
                      Request Access
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                className="d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",

                    width: "100%",
                    padding: "16px 16px",
                  }}
                  className="card_dark darkPrice hovwer_card"
                >
                  <CardContent
                    sx={{
                      padding: "16px 5px",
                    }}
                  >
                    <div className="card_inside_content pricong">
                      <div className="imag_caed">
                        <img
                          alt="Law Blocks AI"
                          src={require("../Image/banner/cross-platform.webp")}
                          className="img-fluid"
                        />
                      </div>
                      <p>Professional Plan</p>

                      <h3>$79/month</h3>
                      <div
                        style={{
                          width: "100%",
                          height: 1,
                        }}
                        className="mt-3 mb-3 grdionet"
                      ></div>
                      <ul>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Access to
                          AI-Powered Document Automation
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Intelligent
                          Contract Creation with Risk Assessment (up to 10,000
                          contracts/month)
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Priority Email
                          and Chat Support
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Advanced
                          Dispute Resolution (ADR, ODR & EDR)
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Advanced
                          Security Features
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Digital
                          Signature
                        </li>
                      </ul>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{
                      justifyContent: "start",
                      padding: "0px 10px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <p className="text_colos">For SMEs</p>
                    <Button
                      size="small"
                      className="laight_button card_butotn padun w-100"
                      onClick={() => (window.location.href = "/comming-soon")}
                    >
                      Request Access
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                className="d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",

                    width: "100%",
                    padding: "16px 16px",
                  }}
                  className="card_dark darkPrice hovwer_card"
                >
                  <CardContent
                    sx={{
                      padding: "16px 5px",
                    }}
                  >
                    <div className="card_inside_content pricong">
                      <div className="imag_caed">
                        <img
                          alt="Law Blocks AI"
                          src={require("../Image/banner/cross-platform.webp")}
                          className="img-fluid"
                        />
                      </div>
                      <p>Enterprise Plan</p>

                      <h3>Custom Pricing</h3>
                      <div
                        style={{
                          width: "100%",
                          height: 1,
                        }}
                        className="mt-3 mb-3 grdionet"
                      ></div>
                      <ul>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>All features of
                          the professional plan +
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Unlimited Smart
                          Contracts per month
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Bulk uploads
                          and contract execution solutions
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Arbitration &
                          Mediation Courts
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>More Add-ons as
                          needed
                        </li>
                        <li>
                          <i class="ri-checkbox-circle-line"></i>Digital
                          Signature
                        </li>
                      </ul>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{
                      justifyContent: "start",
                      padding: "0px 10px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <p className="text_colos">For Large Enterprises</p>
                    <Button
                      size="small"
                      className="laight_button card_butotn padun w-100"
                      onClick={() => (window.location.href = "/comming-soon")}
                    >
                      Request Access
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt bg-gray_section">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className=" mb-4">
                <div
                  className=" overvide headeing_main align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  {/* <span className="lable_badge">Our Blog</span> */}
                  <h2 className="text-captailz text-center">
                    Read resources written by professionals.
                  </h2>
                </div>
              </Grid>
              {posts.map((post, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className="d-flex justify-content-center"
                  data-aos="fade-up"
                >
                  <BlogCard
                    key={index}
                    title={post.title}
                    description={post.description}
                    imagePath={post.imagePath}
                    category={post.category}
                    date={post.date}
                    slug={post.slug}
                  />
                </Grid>
              ))}

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className=" mb-4 d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <a href="/blog" className="card_link">
                  <Button className="laight_button light_cwnte">
                    Browse all articles
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt bg-gray_section pt-0">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className=" mb-4">
                <div
                  className=" overvide headeing_main align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="Text-center mobile_tetxt text-center">
                    Testimonials of Clients & Advocates
                  </h2>
                  <p className="text-center">Our happy clients says about us</p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-delay="200"
              >
                <TestimonialSlider />
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="section_defualt ">
          <Container
            maxWidth="lg"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="200"
          >
            <ContactUs />
          </Container>
        </section>
        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
