import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "What is a Digital Signature?",
      answer:
        "A Digital Signature is an electronic form of a signature that verifies the authenticity and integrity of a digital document. It provides a secure and legally binding way to sign documents electronically, ensuring that the signer is who they claim to be and that the document has not been altered.",
    },
    {
      question: "How does Law Blocks AI ensure the security of my documents?",
      answer:
        "Law Blocks AI ensures the security of your documents through end-to-end encryption, multi-factor authentication, and an audit trail. End-to-end encryption protects your data from unauthorized access, while multi-factor authentication verifies the identity of each signer. The audit trail provides a comprehensive record of the signing process, ensuring transparency and accountability.",
    },
    {
      question: "Is it easy to upload documents on Law Blocks AI?",
      answer:
        "Yes, the process is straightforward and user-friendly, ensuring even those with minimal technical expertise can easily upload documents.",
    },
    {
      question: "How secure are the documents stored on the blockchain?",
      answer:
        "Documents are encrypted and stored with an immutable record, ensuring they are tamper-proof and secure.",
    },
    {
      question: "Can I access my documents anytime?",
      answer:
        "Yes, documents stored on Law Blocks AI can be accessed quickly and efficiently, from anywhere in the world.",
    },
    {
      question: "What types of documents can be stored?",
      answer:
        "Law Blocks AI supports various file formats, making it suitable for legal contracts, medical records, financial documents, and more.",
    },
    {
      question: "Is there customer support available?",
      answer:
        "Yes, Law Blocks AI offers comprehensive support to assist with any questions or issues.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>
          Virtual Courtroom - The Future of Digital Justice with AI and the
          Metaverse
        </title>
        <meta
          property="og:title"
          content="Virtual Courtroom - The Future of Digital Justice with AI and the Metaverse"
        />
        <meta
          property="og:description"
          content="Step into the future with Virtual Courtrooms, where AI and the Metaverse redefine justice for a digital age. Explore how Law Blocks AI revolutionizes the legal system with virtual court hearings, offering enhanced accessibility, efficiency, and security in a fully digital environment."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  property="og:image"
  content="https://lawblocks.io/assets/virtual-courtroom-thumbnail.jpg"
/> --> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/virtual-courtroom"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Virtual Courtroom - The Future of Digital Justice with AI and the Metaverse"
        />
        <meta
          name="twitter:description"
          content="Discover how Virtual Courtrooms are transforming the legal system with AI and the Metaverse. Law Blocks AI offers a cutting-edge platform for virtual court hearings, combining advanced technology with digital justice for a more accessible and efficient legal process."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  name="twitter:image"
  content="https://lawblocks.io/assets/virtual-courtroom-thumbnail.jpg"
/> --> */}
      </Helmet>
      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc  bg-gray_section-hero heaight_excahnge section_fixed_height_sedcion banner_vierutal_hero"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={7}
                className="d-flex align-item-center justiy-content-center"
              >
                <div className="hero_seaction_law align-items-start justify-content-center fixed_hero_height adr-top-padding hero_paddings-top">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Virtual Courtroom The Future of Digital Justice
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Step into the future with Virtual Courtrooms, where AI and
                    the Metaverse redefine justice for a digital age.
                  </p>
                  <Button
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    onClick={navigate}
                  >
                    Get Started
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={5}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="imag_widtjas image_contain_digital d-flex justify-content-center align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <img
                    alt="Virtual Courtroom The Future of Digital Justice"
                    src={require("../../Image/banner/virtual.webp")}
                    className="img-fluid img_center_section_wallet margin_bottons_virtyua- img-padding-top"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-start"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-left">
                    Overview of Virtual Justice in the Metaverse
                  </h2>
                  <p>
                    As the digital world evolves, the concept of justice has
                    also transformed. The Digital justice system has emerged,
                    allowing for virtual court hearings in the Metaverse. This
                    revolutionary approach combines technology with legal
                    processes, enabling court sessions to be conducted entirely
                    online, breaking the constraints of physical courtrooms.
                    Virtual justice in the Metaverse represents the future of
                    the legal system, where disputes are resolved in a fully
                    digital environment, ensuring accessibility and efficiency.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="imag_widtjas image_contain_digital"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <img
                    alt="Virtual Courtroom The Future of Digital Justice"
                    src={require("../../Image/banner/groupvirtual.webp")}
                    className="img-fluid"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt bg-gray_section">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas image_contain_digital"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt="Virtual Courtroom The Future of Digital Justice"
                      src={require("../../Image/banner/group2virtual.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Law Blocks AI in Virtual Courtroom Metaverse</h2>
                    <p>
                      Law Blocks AI leads the way in virtual court hearings,
                      utilizing AI-powered digital justice for precise and
                      unbiased legal decisions. Our platform streamlines the
                      entire process, from case filing to judgment, within the
                      Metaverse. This integration ensures justice is efficient,
                      accessible, and cutting-edge.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
          <Container maxWidth="lg" className="mt-5">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>How It Works</h2>
                    <p>
                      The Metaverse legal justice system replicates traditional
                      court proceedings in a virtual space, where judges,
                      lawyers, and defendants interact using avatars. Virtual
                      hearings allow digital evidence presentation and legal
                      arguments, while AI digital justice tools enhance
                      decision-making accuracy and speed.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas image_contain_digital"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      alt="Virtual Courtroom The Future of Digital Justice"
                      src={require("../../Image/banner/group3virtual.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt  pb-0"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">
                    Benefits of Law Blocks AI <br />
                    Virtual Courtroom
                  </h2>
                </div>
              </Grid>
            </Grid>
            <div class="features-container pl-0 pr-0">
              <div class="feature-box ml-0">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/accesplity.webp")}
                    alt="Virtual Courtroom The Future of Digital Justice"
                  />
                </div>
                <h3>Accessibility</h3>
                <p>
                  Anyone with an internet connection can participate, removing
                  geographical barriers.
                </p>
              </div>

              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/efficancy.webp")}
                    alt="Virtual Courtroom The Future of Digital Justice"
                  />
                </div>
                <h3>Efficiency</h3>
                <p>
                  Cases can be resolved faster with Artificial intelligence
                  digital justice tools that streamline the process.
                </p>
              </div>
              <div class="feature-box ">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/costeffecvtif.webp")}
                    alt="Virtual Courtroom The Future of Digital Justice"
                  />
                </div>
                <h3>Cost-Effective</h3>
                <p>
                  Reduces the need for physical infrastructure, saving time and
                  money for both legal professionals and participants.
                </p>
              </div>
              <div class="feature-box ml-0">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/security.webp")}
                    alt="Virtual Courtroom The Future of Digital Justice"
                  />
                </div>
                <h3>Security</h3>
                <p>
                  Advanced encryption and blockchain technology ensure the
                  confidentiality and integrity of legal proceedings.
                </p>
              </div>
            </div>
          </Container>
        </section>

        <section className="section_defualt  why_digilat">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Why Choose Law Blocks AI for Digital Justice</h2>
                    <p>
                      Virtual justice in the Metaverse is the future of the
                      legal system, and Law Blocks AI is at the forefront. Our
                      platform offers innovative, efficient, and secure
                      solutions for digital justice, empowering both legal
                      professionals and those seeking justice.
                    </p>

                    {/* <ul className="d-flex flex-wrap">
                      <li>
                        <b>Advanced Technology</b>
                      </li>
                      <li>
                        <b>AI digital justice and Metaverse technologies</b>
                      </li>
                      <li>
                        <b>User-Friendly</b>
                      </li>
                      <li>
                        <b>Blockchain Security</b>
                      </li>
                    </ul> */}
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className=" "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/lawvirtualjustis.webp")}
                      className="img-fluid"
                      alt="Virtual Courtroom The Future of Digital Justice"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
